
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_fonts.scss";
            @import "@/scss/common/_vars.scss";
          



























































.dot {
  display: block;
  width: 12px;
  height: 12px;
  background-color: $color-white;
  border-radius: 50%;
  position: relative;

  display: grid;
  place-content: center;

  pointer-events: none;

  &--active {
    width: 24px;
    height: 24px;
  }

  &__background {
    width: 8px;
    height: 8px;
    background-color: $color-primary;
    border-radius: 50%;
    z-index: -1;

    transition: all $basic-transition-200;
  }

  &__container {
    margin: 0 12px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: grid;
    place-content: center;
    pointer-events: all;

    &:hover .dot:not(.dot--active) .dot__background {
      width: 32px;
      height: 32px;
    }
  }

  &__row {
    display: none;
    max-width: 50%;
    min-width: 500px;

    @include for-tablet-landscape-up {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  &__title {
    color: $color-text-inverse;
    font-weight: bold;
    top: -32px;
    position: absolute;
  }
}

.fade-title-enter-active,
.fade-title-leave-active {
  transition: opacity 0.1s linear;
}
.fade-title-enter,
.fade-title-leave-to {
  opacity: 0;
}
