.gui {
  z-index: 1001;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;

  * {
    pointer-events: all;
  }

  &__element {
    position: absolute;

    &--top-right {
      top: 24px;
      right: 24px;
    }

    &--top-left {
      top: 24px;
      left: 24px;
    }

    &--bottom-right {
      bottom: 24px;
      right: 24px;
    }

    &--bottom-left {
      bottom: 24px;
      left: 24px;
    }

    &--bottom-center {
      bottom: 24px;
      left: 50%;
      transform: translateX(-50%);
    }

    &--top-center {
      top: 24px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    transition: all $basic-transition-200;

    width: 20%;
    height: 125px;
    max-width: 150px;
    min-width: 50px;
    max-height: 150px;
    opacity: 1;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: 0 0;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__title {
    bottom: 24px;
    font-weight: bold;
    border-radius: 0 8px 8px 0;
    word-break: break-all;
    max-width: calc(100% - 64px);
    transition: letter-spacing $basic-transition-300;
    max-width: calc(100% - 100px);

    * {
      display: flex;
      width: auto;
      word-break: normal;
      color: $color-text-inverse;
    }

    h1,
    h2 {
      text-transform: uppercase;
      margin-top: 6px;
    }

    p {
      margin-top: 12px;
      max-width: 500px;
    }

    @include for-tablet-landscape-up {
      bottom: 72px;
    }
  }

  &__webvr {
    background-color: $color-text-inverse;
    padding: 12px !important;
    display: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all $basic-transition-200;

    a {
      color: $color-text;
      text-decoration: none;
    }

    &:hover {
      padding: 12px 24px !important;
    }

    @include for-tablet-landscape-up {
      display: block;
    }
  }
}

.side-menu {
  &__button {
    padding: 0 0 12px 12px;

    &-icon {
      position: relative;
      width: 32px;
      height: 24px;

      .line {
        display: block;
        width: 100%;
        height: 3px;
        background-color: $color-text-inverse;
        right: 0;
        position: absolute;

        transition: width $basic-transition-200;

        &:nth-of-type(1) {
          top: 0;
        }

        &:nth-of-type(2) {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:nth-of-type(3) {
          bottom: 0;
        }
      }
    }

    &:hover {
      .line {
        &:first-of-type {
          width: 80%;
        }

        &:last-of-type {
          width: 60%;
        }
      }
    }
  }
}

.actions {
  position: absolute;
  bottom: 24px;
  right: 24px;
  display: grid;
  grid-gap: 16px;
  grid-template: 1fr 1fr / 1fr;

  img,
  a {
    display: block;
    width: 25px;
    opacity: 0.8;
    transition: opacity $basic-transition-200;

    &:hover {
      opacity: 1;
    }
  }

  .gallery {
    transform: scale(0.8);
  }

  @include for-tablet-landscape-up {
    bottom: 24px;
    right: 48px;
    grid-template: 1fr / 1fr 1fr;
  }
}

.credit {
  display: block;
  visibility: visible;
  width: 10px;
  height: 10px;
  background-color: transparent;
  top: auto;
  z-index: 3;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  font-size: 1.2rem;
  color: #fff;
  letter-spacing: 0;
  text-decoration: none;
  white-space: nowrap;
  opacity: 0.5;
  box-shadow: 0px 0px 120px 10px #00000047;

  display: none;

  &:hover {
    text-decoration: underline;
  }

  @include for-tablet-landscape-up {
    display: block;
  }
}
