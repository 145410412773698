
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_fonts.scss";
            @import "@/scss/common/_vars.scss";
          







































































































































.modal {
  &__container {
    display: grid;
    position: absolute;
    background: rgba($color-black, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
    opacity: 0;
    visibility: hidden;
    transition: opacity $basic-transition-500 0.1s;

    &_open {
      pointer-events: all;
      opacity: 1;
      visibility: visible;

      .modal__inner {
        opacity: 1;
        top: unset;
        bottom: 0;
        transform: translateX(-50%);
        visibility: visible;

        width: 100%;
        height: auto;
        max-height: 75%;

        .modal__content {
          opacity: 1;
        }

        @include for-desktop-up {
          width: 70%;
          height: auto;
          max-height: 80%;
          max-width: 800px;
          border-radius: 4px;
          bottom: unset;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .modal__outer {
        pointer-events: all;
        opacity: 0.3;
      }
    }
  }

  &__inner {
    background-color: $color-white;
    border-radius: 4px 4px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 1001;
    position: absolute;
    opacity: 0;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 20px;
    height: 20px;

    transition: height $basic-transition-500 0.1s,
      width $basic-transition-500 0.1s, opacity $basic-transition-500 0.1s;

    .modal__content {
      width: 100%;
      opacity: 0;
      transition: opacity $basic-transition-300 0.5s;
    }

    font-size: 1.6rem;
    line-height: 1.4;
  }

  &__outer {
    pointer-events: all;
    cursor: pointer;
    opacity: 0;
  }

  &--info {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;
    background-color: $color-primary;
    top: 0;
    left: 0;

    img {
      height: 90%;
      filter: invert(92%) sepia(100%) saturate(0%) hue-rotate(202deg)
        brightness(106%) contrast(106%);
    }
  }

  &--close {
    position: absolute;

    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: transparent;

    img {
      width: 28px;
      height: 28px;
      transition: transform $basic-transition-300;

      filter: invert(6%) sepia(3%) saturate(179%) hue-rotate(314deg)
        brightness(96%) contrast(85%);
    }

    &:hover img {
      transform: rotate(90deg);
    }

    &:active {
      transform: scale(1.05);
    }
  }

  &__content {
    padding: 24px;
    overflow: auto;

    text-align: left;

    &__title {
      margin: 24px 0;
      font-size: 3.6rem;
    }

    &__gallery {
      overflow: hidden;
      cursor: grab;
      overflow: hidden;

      .img__wrapper {
        width: 250px;
        min-height: 150px;
        max-height: 250px;
        margin: 24px 16px;
        overflow: hidden;
        border-radius: 4px;

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          pointer-events: none;
          transition: all $basic-transition-200;
        }

        &:hover {
          cursor: pointer;

          img {
            transform: scale(1.1);
          }
        }
      }

      :active {
        cursor: grabbing;
      }
    }

    iframe {
      width: 100%;
      min-height: 300px;
      margin-top: 24px;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      @include for-tablet-portrait-up {
        flex-direction: row;
        justify-content: space-between;
      }

      a {
        margin-top: 24px;
        color: darken($color-primary, 10%);
        transition: color $basic-transition-200;
        display: block;

        img {
          height: 24px;
          vertical-align: middle;
          margin-left: 0;
          transition: margin $basic-transition-200;
        }

        &:hover {
          color: $color-text;

          img {
            margin-left: 4px;
          }
        }
      }
    }
  }
}

.flicking-camera {
  display: inline-flex;
}
