/////////////////// FF Mark

/* Bold */
@font-face {
  font-family: "FF Mark";
  src: url("/assets/fonts/FontFont - Mark W1G Bold.otf") format("otf");
  font-style: bold;
}

/* Ultra */
@font-face {
  font-family: "FF Mark";
  src: url("/assets/fonts/FontFont - Mark W1G Ultra.otf") format("otf");
  font-style: bolder;
}

/////////////////// SK Reykjavik

/* Regular */
@font-face {
  font-family: "SK Reykjavik";
  src: url("/assets/fonts/Salih Kizilkaya - SK Reykjavik Regular.ttf")
    format("ttf");
  font-style: normal;
}

/* Medium */
@font-face {
  font-family: "SK Reykjavik";
  src: url("/assets/fonts/Salih Kizilkaya - SK Reykjavik Medium.ttf")
    format("ttf");
  font-style: bold;
}

/* Bold */
@font-face {
  font-family: "SK Reykjavik";
  src: url("/assets/fonts/Salih Kizilkaya - SK Reykjavik Bold.ttf")
    format("ttf");
  font-style: bolder;
}
