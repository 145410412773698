
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_fonts.scss";
            @import "@/scss/common/_vars.scss";
          






















































































































































$hotspot-dimension: 32px;

.hotspot {
  /*
    Position on ath/atv : krpano hack
  */
  position: absolute;
  cursor: pointer !important;
  pointer-events: all;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: $hotspot-dimension;
  height: $hotspot-dimension;
  border-radius: 50px;
  background: $color-white;

  display: grid;
  place-content: center;

  box-shadow: 0 0 32px 8px rgba(0, 0, 0, 0.2);
  transition: all $basic-transition-200;

  &__inner {
    &__navigation {
      width: calc(#{$hotspot-dimension} / 3);
      height: calc(#{$hotspot-dimension} / 3);
      background: $color-primary;
      border-radius: 50px;
      transition: all $basic-transition-200;
      overflow: hidden;
      display: grid;
      place-content: center;

      img {
        opacity: 0;
        height: 100%;
        width: 100%;
        transition: all $basic-transition-200;
      }
    }

    &__info,
    &__small-info {
      width: $hotspot-dimension;
      height: $hotspot-dimension;
      border-radius: 50px;

      img {
        // opacity: 0;
        height: 100%;
        width: 100%;
        transition: all $basic-transition-200;
      }
    }
  }

  &__content {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;

    height: $hotspot-dimension;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 20px 0 50px;
    border-radius: 50px;
    opacity: 0;
    overflow: hidden;
    transition: all $basic-transition-200;

    span {
      opacity: 0;
      transition: all $basic-transition-1000;
    }

    &__bg {
      background: $color-primary;
      width: 0%;
      height: 100%;
      left: 0;
      transition: all $basic-transition-500;
      position: absolute;
      z-index: -1;
    }

    &--small-info {
      padding: 12px;
      flex-direction: column;
      // min-height: 200px;
      height: auto;
      border-radius: 8px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: -1;
      border: 4px solid $color-white;
      background-color: $color-primary;
      opacity: 0;
      transition: all $basic-transition-200;

      display: flex;
      align-items: flex-start !important;
      flex-direction: column;
      justify-content: flex-start;
      min-width: 250px;
      text-align: left;

      span {
        font-weight: bold;
        margin-bottom: 12px;
        font-size: 1.6rem;
        white-space: break-spaces;
        transition: all $basic-transition-500;
      }

      p {
        font-weight: normal;
        opacity: 0;
        font-size: 1.4rem;
        transition: all $basic-transition-500;
        white-space: break-spaces;
      }
    }

    * {
      color: $color-text-inverse;
      font-weight: bold;
      font-size: 14px;
    }
  }

  &:hover,
  &.touched {
    .hotspot__inner {
      background: darken($color-primary, 20);
      width: calc(#{$hotspot-dimension} - 6px);
      height: calc(#{$hotspot-dimension} - 6px);

      img {
        opacity: 1;
        filter: invert(92%) sepia(100%) saturate(0%) hue-rotate(202deg)
          brightness(106%) contrast(106%);
      }
    }

    .hotspot__content {
      opacity: 1;
      pointer-events: all;

      span,
      p {
        opacity: 1;
      }

      &__bg {
        width: 100%;
      }
    }
  }
}
