$color-text: #000000;
$color-text-inverse: #ffffff;

$color-black: #000000;
$color-white: #ffffff;

$color-primary: #e4bd78;
$color-secondary: #000000;
$color-accent: #000000;

$font-family-title: "FF Mark", sans-serif;
$font-family-text: "SK Reykjavik", sans-serif;

$title-size-mobile: 2.9rem;
$title-size-tablet-portrait: 3.6rem;
$title-size-tablet-landscape: 4.2rem;
$title-size-desktop: 4.8rem;

$subtitle-size-mobile: 2rem;
$subtitle-size-tablet-portrait: 2rem;
$subtitle-size-tablet-landscape: 2.2rem;
$subtitle-size-desktop: 2.2rem;

$text-size-mobile: 1.6rem;
$text-size-tablet-portrait: 1.6rem;
$text-size-tablet-landscape: 1.8rem;
$text-size-desktop: 1.8rem;

$basic-transition-100: 100ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-200: 200ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-300: 300ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-500: 500ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-1000: 1s cubic-bezier(0.86, 0, 0.07, 1);
