
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_fonts.scss";
            @import "@/scss/common/_vars.scss";
          
























.splash {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 5000;

  background-color: $color-accent;

  display: grid;
  place-content: center;

  text-align: center;

  &__logo {
    height: 150px;
    width: 150px;
    // animation: fade-in-out 2s ease-in infinite;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  p {
    margin: 24px auto;
    letter-spacing: 2px;
    font-weight: bold;
    color: $color-text-inverse;
    // animation: fade-in-out 2s ease-in infinite;
  }

  .poppr a {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    color: $color-text-inverse;
    text-decoration: none;
    opacity: 0.8;
    font-size: 1.2rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

@keyframes fade-in-out {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}
