
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_fonts.scss";
            @import "@/scss/common/_vars.scss";
          
html,
body,
#app {
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: $font-family-text;
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
  padding: 0;

  * {
    font-family: $font-family-text;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-title;
  font-weight: 900;
}

p,
blockquote,
a,
span {
  font-family: $font-family-text;
  font-weight: normal;
}

h1,
h1 span {
  font-size: $title-size-mobile;
  line-height: calc(#{$title-size-mobile});

  @include for-tablet-portrait-up {
    font-size: $title-size-tablet-portrait;
    line-height: calc(#{$title-size-tablet-portrait});
  }

  @include for-tablet-landscape-up {
    font-size: $title-size-tablet-landscape;
    line-height: calc(#{$title-size-tablet-landscape});
  }

  @include for-desktop-up {
    font-size: $title-size-desktop;
    line-height: calc(#{$title-size-desktop});
  }
}

h2,
h2 span {
  font-size: $subtitle-size-mobile;
  line-height: calc(#{$subtitle-size-mobile});

  @include for-tablet-portrait-up {
    font-size: $subtitle-size-tablet-portrait;
    line-height: calc(#{$subtitle-size-tablet-portrait});
  }

  @include for-tablet-landscape-up {
    font-size: $subtitle-size-tablet-landscape;
    line-height: calc(#{$subtitle-size-tablet-landscape});
  }

  @include for-desktop-up {
    font-size: $subtitle-size-desktop;
    line-height: calc(#{$subtitle-size-desktop});
  }
}

p,
p span,
a,
a span {
  font-weight: normal;
  font-size: $text-size-mobile;
  line-height: calc(#{$text-size-mobile}+ 0.5rem);

  @include for-tablet-portrait-up {
    font-size: $text-size-tablet-portrait;
    line-height: calc(#{$text-size-tablet-portrait} + 0.5rem);
  }

  @include for-tablet-landscape-up {
    font-size: $text-size-tablet-landscape;
    line-height: calc(#{$text-size-tablet-landscape} + 0.5rem);
  }

  @include for-desktop-up {
    font-size: $text-size-desktop;
    line-height: calc(#{$text-size-desktop} + 0.5rem);
  }
}

#krpanoObject {
  z-index: 1000;
}

body > #krpanoObject {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.btn {
  text-transform: uppercase;
  position: relative;
  border-radius: 6px;
  color: $color-black;
  padding: 12px;
  // letter-spacing: 2px;
  text-decoration: none;
  line-height: 18px;
  top: 24px;
  transition: $basic-transition-200;

  display: flex;
  align-items: center;

  &_shadow {
    box-shadow: 0px 0px 120px 10px #00000047;
  }

  &:active {
    transform: scale(1.05);
  }
}

.btn {
  text-transform: uppercase;
  position: relative;
  border-radius: 6px;
  color: $color-black;
  padding: 12px;
  // letter-spacing: 2px;
  text-decoration: none;
  line-height: 18px;
  top: 24px;
  transition: $basic-transition-200;

  display: flex;
  align-items: center;

  &_shadow {
    box-shadow: 0px 0px 120px 10px #00000047;
  }

  &:active {
    transform: scale(1.05);
  }
}

::-webkit {
  &-scrollbar {
    width: 4px;

    &-track {
      border-radius: 50px;
      background: darken($color-white, 5%);
    }

    &-thumb {
      border-radius: 50px;
      background: $color-primary;

      &:hover {
        background: darken($color-primary, 10%);
      }
    }
  }
}

/* Tinybox styling */
.tinybox {
  z-index: 8000 !important;
  background: rgba(0, 0, 0, 1) !important;

  &__content {
    &__image {
      border-radius: 4px !important;
    }

    &__control--close {
      top: 16px !important;
    }
  }
}

.gallery {
  .flicking-camera {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
  }
}

.modal {
  .flicking-camera {
    display: inline-flex !important;
  }
}

.invisible {
  opacity: 0 !important;
  pointer-events: none !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  z-index: -1 !important;
}
