
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_fonts.scss";
            @import "@/scss/common/_vars.scss";
          

































.home {
  .languages a {
    color: $color-text;
  }
}
